/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "EmailCustomerInvite",
            "endpoint": "https://umzp4ienqi.execute-api.eu-west-2.amazonaws.com/test",
            "region": "eu-west-2"
        },
        {
            "name": "NextOrderNumber",
            "endpoint": "https://zwhb5ryjnj.execute-api.eu-west-2.amazonaws.com/test",
            "region": "eu-west-2"
        },
        {
            "name": "SesTemplateEmailer",
            "endpoint": "https://mu3shlzk4m.execute-api.eu-west-2.amazonaws.com/test",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://zts27dieg5fsdl5o4oqic44qqq.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-2:bfa09964-be03-4f04-acca-c3b0a249e397",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_N03lAiteN",
    "aws_user_pools_web_client_id": "1g709kc69c0qd91g6kg5ddflnn",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "cwsstorage145103-test",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "aws_dynamodb_all_tables_region": "eu-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "NextOrderNumber-test",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
